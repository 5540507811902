import React, { useState, useEffect } from 'react';
import Link from 'next/link';
//i18n
import { useTranslation } from 'react-i18next';
import {
  Container,
  Row,
  Col,
  Card,
  Placeholder,
  Button,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Load Styles
import styles from '../../styles/components/integrations/WhatsApp.module.scss';


function WhatsApp() {
  const { t, i18n } = useTranslation();
  
 

  const resetForm = () => {
   
  };

  return (
   
        <Container className={`${styles['whatsApp']}`}>
         <div
         className={`${styles['fixedCircle']} ${styles['setfixed']}`}>
            <a 
            alt="Connect to WhatsApp"
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send?phone=436602255984"
            >
              <FontAwesomeIcon 
              style={{
                height:'35px',
                color:'white'
              }}
              icon="fa-brands fa-whatsapp" />
            </a>
          </div>
         

        </Container>
        
  );
}

export default WhatsApp;
