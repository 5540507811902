import React, { Fragment,useLayoutEffect, useState, useEffect } from 'react';
import Image from 'next/image';
import {Container,Row,Col, Button} from 'react-bootstrap';
//i18n
import { useTranslation } from 'react-i18next';
//Load Styles
import styles from '../../../styles/layouts/sections/Services.module.scss'

function SetElementSizeBasedOnWindowWidth(sizeArray){
  
  if(sizeArray.length > 0)
  {
    const width = sizeArray[0];
    if(width >= 1600)
    {
      return {width:'60%'};
    }
    else if(width >= 1300)
    {
      return {width:'70%'};
    }
    else  if(width >= 1000)
    {
      return {width:'80%'};
    }
    else  if(width >= 700)
    {
      return {width:'90%'};
    }
    else  if(width >= 10)
    {
      return {width:'90%'};
    }
  }

  return {width:'50%'};
}




function ServicesSection () { 
  const { t, i18n } = useTranslation();
  const [servicesData, setServicesData] = useState([]);
  const [size, setSize] = useState([0, 0]);
  const [moduloValue, setModuloValue] = useState(2);
  const [isLoading, setIsLoading] = useState(true);

  const getServiceItems = async () => {
    try {
      const response = await fetch(`/api/backend/services?locale=${i18n.language}`, {
      headers: {
        Accept: "application/json",
      }
      });
      const dataFromBackend = await response.json();
      setServicesData(dataFromBackend);
      setIsLoading(false)
    }
    catch (error) {
      console.log('[Error loading Services Data] - ' + error);      
    }
  }

    useEffect(() => {       
      async function fetchDataFromNextAPI() {   
        await getServiceItems();
        }      
        fetchDataFromNextAPI();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);
    


    
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
        setModuloValue(window.innerWidth <= 767 ? 1 : 2);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, size);

    if (isLoading===true) return null;

    return(
    <Fragment>
            <section className="portfolio-creative portfolio-section p-0">
                <Container fluid={true} style={SetElementSizeBasedOnWindowWidth(size)}>             
                    <Row>
                        {
                            servicesData.map((data, i) => {                              
                              if((i + 1) % moduloValue == 0)
                              {
                                 return (  
                                      <Fragment key={i} >  
                                       <Row className={styles["fragmetServices"]}>                                  
                                        <Col lg="6" md="6" className="p-0 isotopeSelector" >     
                                            {
                                              data.attributes.image.data != undefined ?
                                              <Image priority
                                              width={`${data.attributes.image.data.attributes.formats.small.width}px`}
                                              height={`${data.attributes.image.data.attributes.formats.small.height}px`}
                                              alt={data.attributes.heading} 
                                              title={data.attributes.heading} 
                                              src={`${process.env.NEXT_PUBLIC_BACKEND_API_URL}${data.attributes.image.data.attributes.formats.small.url}`}
                                              />                    
               
                                                :<p>Image not defined in backend</p>
                                              }                                      
                                              {/* <Image priority
                                              width={`${data.attributes.image.data.attributes.formats.small.width}px`}
                                              height={`${data.attributes.image.data.attributes.formats.small.height}px`}
                                              alt={data.attributes.heading} 
                                              title={data.attributes.heading} 
                                              src={`${process.env.NEXT_PUBLIC_BACKEND_API_URL}${data.attributes.image.data.attributes.formats.small.url}`}
                                              /> */}
                                       </Col>                                        
                                        <Col lg="6" md="6" className="p-0 h-100 my-auto homepage-services-singlecontainer">
                                            <div className="portfolio-text m-auto text-center">
                                                <h2 className={styles["headingServices"]}>
                                                    {data.attributes.heading}
                                                </h2>
                                                <p className={styles["servicesDescription"]}>
                                                    {data.attributes.description}
                                                </p>
                                                
                                                <Button className={styles.callToAction} variant="primary" href={data.attributes.button_url}>{data.attributes.button_text}</Button>

                                            </div>
                                          </Col>
                                          </Row> 
                                      </Fragment>
                                 )
                              }
                              else
                              {
                                 return (                     
                                  <Fragment key={i} >
                                     <Row className={styles["fragmetServices"]}>  
                                    <Col lg="6" md="6" className="p-0 h-100 my-auto homepage-services-singlecontainer">
                                        <div className="portfolio-text m-auto text-center">
                                        <h2 className={styles["headingServices"]}>
                                                    {data.attributes.heading}
                                                </h2>
                                                <p className={styles["servicesDescription"]}>
                                                    {data.attributes.description}
                                                </p>
                                                <Button className={styles.callToAction} variant="primary" href={data.attributes.button_url}>{data.attributes.button_text}</Button>
                                         </div>
                                      </Col>                                        
                                    <Col lg="6" md="6" className="p-0 isotopeSelector" >
                                    {
                                              data.attributes.image.data != undefined ?
                                              <Image priority
                                              width={`${data.attributes.image.data.attributes.formats.small.width}px`}
                                              height={`${data.attributes.image.data.attributes.formats.small.height}px`}
                                              alt={data.attributes.heading} 
                                              title={data.attributes.heading} 
                                              src={`${process.env.NEXT_PUBLIC_BACKEND_API_URL}${data.attributes.image.data.attributes.formats.small.url}`}
                                              />                    
               
                                                :<p>Image not defined in backend</p>
                                              }  
                                    {/* <Image priority
                                              width={`${data.attributes.image.data.attributes.formats.small.width}px`}
                                              height={`${data.attributes.image.data.attributes.formats.small.height}px`}
                                              alt={data.attributes.heading} 
                                              title={data.attributes.heading} 
                                              src={`${process.env.NEXT_PUBLIC_BACKEND_API_URL}${data.attributes.image.data.attributes.formats.small.url}`}
                                              />  */}
                                              </Col>   
                                              </Row>   
                                  </Fragment>     
                                                             
                             )
                              }
                            })
                        }
                    </Row>
                </Container>
            </section>
    </Fragment>
   )
}
export default ServicesSection;