import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import styles from '../styles/Home.module.scss'
import { Container, Row, Col } from 'react-bootstrap';
// Components for building main Homepage
import Footer from '../components/common/footer';
import Header from '../components/common/header';
import Placeholder from '../components/common/placeholder';
import LoadingSpinnerUno from '../components/spinner/LoadingSpinnerUno';
import BasicSlider from '../components/slider/BasicSlider';
// import Posts from '../components/blog/Posts';
import Posts from '../components/blog/PostTwo';
import AdvertismentUno from '../components/advertisment/AdvertismentUno';
import WhatsApp from '../components/integrations/WhatsApp';
//i18n
import { useTranslation } from 'react-i18next';
// Custom NPM Package
import { BackToTopButton,CookieBanner } from '@benchvondaranch/npm.react-ui-library';
// Using Global Redux State
import { useSelector, useDispatch } from 'react-redux';
import { updateLocalStorage } from '../store/userSlice';
import variables from '../styles/variables.module.scss';
import ServicesSection from './layouts/sections/services';
import { fetchWebsiteSettings } from '../store/websiteSettingsSlice';

export default function Home() {
   // const [isLoading, setIsLoading] = useState(true);//for prod
  const [isLoading, setIsLoading] = useState(true);//for test
  const [showCookieBanner, setShowCookieBanner] = useState(true);
  // const [showAdvertisment, setShowAdvertisment] = useState(false);  
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const [settingsData, setSettingsData] = useState({});
  const [advertismentData, setAdvertismentData] = useState({});
  const websiteSettingsStatus = useSelector(state => state.websiteSettings.status)

  const getAdvertismentData = async () => {
    try {
     
      if(settingsData.General?.show_advertisment_on_home_page === false)
      {
        debugger
        console.log('Showing Ads disabled on HP')
      }
      else
      {
      const response = await fetch(`/api/backend/advertisment?locale=${i18n.language}`, {
      headers: {
        Accept: "application/json",
      }
      });
      const advertismentDataFromBackend = await response.json();
      setAdvertismentData(advertismentDataFromBackend);
      }
    }
    catch (error) {
      console.log('[Error loading Advertisment Data] - ' + error);      
    }
  }


  const getSettingsData = async () => {
        
    try {
          const response = await fetch(`/api/backend/settings?locale=${i18n.language}`, {
          headers: {
            Accept: "application/json",
          },
    });
    
    const settingsDataFromBackend = await response.json();
    setSettingsData(settingsDataFromBackend);
    } catch (error) {

        console.log('[Error - loading Settings] - ' + error);

    }
    finally{
        setIsLoading(false)
    }
  };

 
  useEffect(() => {
    dispatch(fetchWebsiteSettings(i18n.language));
}, [dispatch,i18n.language])

useEffect(() => {
    if (websiteSettingsStatus === 'idle') {
      dispatch(fetchWebsiteSettings(i18n.language))          
    }
    else if (websiteSettingsStatus === 'succeeded')
    {  
        setIsLoading(false);
    }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteSettingsStatus, i18n.language, dispatch])


useEffect(() => {       
    async function fetchDataFromNextAPI() {           
        // await getSettingsData();  
        // await getAdvertismentData();
                       
      }      
      fetchDataFromNextAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[])

  // useEffect(() => {
  //   //Simulate long loading in miliseconds
  //   //For Production Usage set to Minimum! 
  //   // setTimeout(() => setIsLoading(false), 100);
    
  // },[])


  useEffect(() => {
  if (typeof window !== 'undefined') {
    // console.log(JSON.stringify(settingsData));
    // console.log('Checking if GDPR was alreday accepted...')
     const cookieBannerDataFromLocalStorage = localStorage.getItem('cookieBannerData') ? JSON.parse(localStorage.getItem('cookieBannerData')) : undefined;
     
     if(cookieBannerDataFromLocalStorage?.accepted==="true")
     {
      // console.log('GDPR is Accepted: Disable Cookie Banner and show Advertisment if necessary...')
      // Accept all is not too far away
      setShowCookieBanner(false);
      // setShowAdvertisment(true);
     }
     else{
      // console.log('Seems acceptance is still missing')
     }
     // can use localStorage here
    } else {      
      // can't use localStorage
    }
  },[settingsData])

  const selectAll = (e) => {
    const cookieData = {
      "necessary":"true",
      "marketing":"true",
      "performance":"true",
      "accepted":"true",
      "acceptedDateTime":`${new Date()}`,
      "savedViaButtonAction":`${e.target.getAttribute('data-c-action')}`,      
    }
    localStorage.setItem('cookieBannerData', JSON.stringify(cookieData));
    // setCookieBannerData(cookieData)
    dispatch(updateLocalStorage(cookieData))
    setShowCookieBanner(false);
    // setShowAdvertisment(true);    
  }

  const saveSelection = (e) => {
    // Get All Checkboxes of CookieBanner
    const cbNecessary = document.getElementById('necessary').checked;
    const cbMarketing = document.getElementById('marketing').checked;
    const cbPerformance = document.getElementById('performance').checked;
    const cookieData = {
      "necessary": `${cbNecessary}`,
      "marketing":`${cbMarketing}`,
      "performance":`${cbPerformance}`,
      "accepted":"true",
      "acceptedDateTime":`${new Date()}`,
      "savedViaButtonAction":`${e.target.getAttribute('data-c-action')}`,      
    }
    localStorage.setItem('cookieBannerData', JSON.stringify(cookieData));
    dispatch(updateLocalStorage(cookieData))
    setShowCookieBanner(false);
    // setShowAdvertisment(true);  
  }

  

  if (isLoading===true) return null;

  return (

    <React.Suspense fallback="loading">
      
    <div className={styles.container} id="page-container" >
     {
       isLoading === false ? 
       <>
      <Head>
        <title>{t('meta_tag_home_page_title')}</title>
        <meta name="description" content={t('meta_tag_home_page_description')} />
        <link rel="icon" href="/favicon.ico" />
      </Head>


      <Header  />


     <BasicSlider />
    
     
        <Container className="pt-4" fluid >
        <Row className='justify-content-center webpageTitle' style={{
          backgroundColor:variables.initial_standardHeaderBanner_backgroundColor,
          color:variables.initial_standardHeaderBanner_fontColor,
       
          }}>
          <Col></Col>
          <Col  xs={'auto'} sm={'auto'} md={'auto'}  lg={'auto'}  xl={'auto'}>
            <h2 >{t("heading_myservices")}</h2>
          </Col>
          <Col></Col>
        </Row>  
          </Container>

          <ServicesSection/>

          {/* <Posts></Posts> */}

     {/* <WhatsApp></WhatsApp> */}

     <BackToTopButton
      backgroundColor={variables.initial_backToTop_backgroundColor}
      borderColor='white'
      borderWidth='2px'
      width='40px'
      height='40px'
      iconColor='white'
      iconType={1}
      iconMarginTop='-10px'/>
     <Footer settings={settingsData.Footer} />
{/*       
        <AdvertismentUno 
        showAdvertisment={showCookieBanner === false && settingsData.General?.show_advertisment_on_home_page === true ? true : false}
        title={advertismentData.title}
        subtitle={advertismentData.subtitle}
        media={advertismentData.media}
        callToActionText={advertismentData.call_to_action_text}
        callToActionURL={advertismentData.call_to_action_url}
        callToActionTarget={advertismentData.call_to_action_target}
        
        ></AdvertismentUno> */}
         
    

      </>
      :
       <LoadingSpinnerUno></LoadingSpinnerUno>
    }
    </div>
    <CookieBanner
      title={ t("cookie_banner_title") }
      subtitle={ t("cookie_banner_subtitle") }
      description={ t("cookie_banner_description") }
      text_save_selection={ t("cookie_banner_text_save_selection") }
      text_accept_all={ t("cookie_banner_text_accept_all") }
      text_checkbox_option1={ t("cookie_banner_text_checkbox_option1") }
      text_checkbox_option2={ t("cookie_banner_text_checkbox_option2") }
      text_checkbox_option3={ t("cookie_banner_text_checkbox_option3") }
      gdpr_link={ t("cookie_banner_gdpr_link") }
      gdpr_link_text={ t("cookie_banner_gdpr_link_text") }
      imprint_link={ t("cookie_banner_imprint_link") }
      imprint_link_text={ t("cookie_banner_imprint_link_text") }
      display={showCookieBanner}
      handleAcceptAllClick={selectAll}
      handleSaveSelectionClick={saveSelection}
      ></CookieBanner>

      

    </React.Suspense>
  )
}
